import React, { useEffect, useState } from 'react'
import axios from 'axios'

export default function Update() 
{
    const [data,setData] = useState({id:1,userType:1,studyNo:'',studyName:'',testItems:'',name:''})
    const [submitting,setSubmitting] = useState(false)
    const [exporting,setExporting] = useState(false)

    async function update(e)
    {
        try 
        {
            e.preventDefault();
            setSubmitting(true)
            let res = await axios.post(`${process.env.REACT_APP_BASE_URL}/study`,{...data}) 
            alert('Updated')
            setData({id:1,userType:2,studyNo:'',studyName:'',testItems:'',name:''})
            setSubmitting(false)
        } catch (error) {
            setSubmitting(false)
            alert('Error updatinng')
        }
    }

    async function deleteStudy(e)
    {
        try 
        {
            e.preventDefault();
            setSubmitting(true)
            let res = await axios.post(`${process.env.REACT_APP_BASE_URL}/delete`) 
            alert('Data Cleared')
            setData({id:1,userType:2,studyNo:'',studyName:'',testItems:'',name:''})
            setSubmitting(false)
        } catch (error) {
            setSubmitting(false)
            alert('Error Clearing Data')
        }
    }

    async function exportPdf(e)
    {
        try 
        {
            e.preventDefault();
            setExporting(true)
            let response = await axios.post(`${process.env.REACT_APP_BASE_URL}/export`,{},{responseType:'blob'}) 
            // Create a temporary URL for the blob data
                const url = window.URL.createObjectURL(new Blob([response.data]));

                // Create a temporary anchor element
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.setAttribute('download', 'output.pdf'); // Set the filename for download

                // Append the anchor to the document body and trigger a click event to start download
                document.body.appendChild(anchor);
                anchor.click();

                // Clean up by removing the anchor and revoking the URL
                document.body.removeChild(anchor);
                window.URL.revokeObjectURL(url);
                setExporting(false)
        } catch (error) {
            setExporting(false)
                alert('Data Not Found')
        }
    }
  return (
    <div className='flex h-screen w-full items-center justify-center bg-[#f3f3f3]'>
        <div className='block p-6 border border-[#e3e3e3] bg-white min-h-[520px] w-full max-w-[480px] mx-auto rounded-md'>
            <div>
                <h2 className='text-center font-bold text-xl'>Pre-Discussion Form</h2>
            </div>
        <form className='w-full' onSubmit={update}>
            <div className='w-full mb-2'>
                <label>Select User</label>
                <select className='w-full block' required onChange={(e)=>setData(prev=>({...prev,userType:e.target.value}))}>
                    <option value={1}>User 1</option>
                    <option value={2}>User 2</option>
                    <option value={3}>User 3</option>
                    <option value={4}>User 4</option>
                    <option value={5}>User 5</option>
                </select>
            </div>
            <div className='w-full mb-2'>
                <label>Study Number</label>
                <input type='text' value={data.studyNo} required onChange={(e)=>setData(prev=>({...prev,studyNo:e.target.value}))}/>
            </div>
            <div className=''>
                <label>Study Name</label>
                <input type='text' value={data.studyName} required onChange={(e)=>setData(prev=>({...prev,studyName:e.target.value}))}/>
            </div>
            <div className=''>
                <label>No. of Sample Items</label>
                <input type='text' value={data.testItems} required onChange={(e)=>setData(prev=>({...prev,testItems:e.target.value}))}/>
            </div>
            <div className=''>
                <label>User Name</label>
                <input type='text' value={data.name} required onChange={(e)=>setData(prev=>({...prev,name:e.target.value}))}/>
            </div>
            <div className='flex mt-4 items-end justify-end'>
                <button type='submit' disabled={submitting ? true : false} className='bg-[#161661] disabled:bg-[#a3a3a3] inline-block px-5 py-2 rounded-md text-white font-medium'>Approve</button>
            </div>
        </form>
            <div className='flex mt-4 items-end justify-end'>
                <button type='button' disabled={exporting} onClick={exportPdf} className='bg-[#161661] hover:bg-[#0e0e23] inline-block px-5 py-2 rounded-md text-white font-medium cursor-pointer disabled:cursor-not-allowed'>Export PDF</button>
            </div>
            <div className='fixed left-10 bottom-10 flex mt-4 items-end justify-end'>
                <button type='button' disabled={exporting} onClick={deleteStudy} className='bg-[#ff5757] hover:bg-[#ba4b4b] inline-block px-3 py-2 rounded-md text-white font-medium cursor-pointer disabled:cursor-not-allowed text-xs'>Clear Data</button>
            </div>
        </div>
    </div>
  )
}








